var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide bg-white"},[_c('router-link',{attrs:{"to":_vm.product != null
        ? {
            name: 'product.detail',
            params: {
              name: ("" + (_vm.product.en_title ? _vm.product.en_title.replaceAll(' ','-') + '-' : 'detail-') + (_vm.product.id)),
            },
          }
        : ''}},[_c('div',{staticClass:"campaigns-slide-img"},[(_vm.product && _vm.mainImage)?_c('img',{attrs:{"loading":"lazy","src":_vm.getImage(_vm.mainImage, 'lg'),"alt":_vm.product.title}}):_vm._e()]),_c('div',{staticClass:"campaigns-slide-bottom text-center d-flex flex-column justify-content-end"},[_c('h6',{staticClass:"weight-bold text-color-444 fontsize-medium mb-0"},[_vm._v(" "+_vm._s(_vm.product.title.substr(0, 33))+" "+_vm._s(_vm.product.title.length > 33 ? "..." : "")+" ")]),_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"fontsize-medium ml-1 weight-bold text-color-themeRed"},[_vm._v(_vm._s(_vm._f("price")(_vm.mainPrice)))]),_c('span',{staticClass:"fontsize-mini text-color-999"},[_vm._v("تومان")]),(_vm.discountAmount != 0)?_c('span',{staticClass:"pr-1"},[_c('svg',{staticClass:"bi bi-chevron-double-right",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"11","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill":"#aaa","fill-rule":"evenodd","d":"M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"}}),_c('path',{attrs:{"fill":"#aaa","fill-rule":"evenodd","d":"M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"}})]),_c('span',{staticClass:"fontsize-small line-through mr-1 text-color-666"},[_vm._v(_vm._s(_vm._f("price")((_vm.mainPrice + _vm.discountAmount))))])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }