<template>
  <section
    v-if="products && products.length"
    :id="uniqId"
    class="recommended-products container pt-4 mt-5 mt-sm-4 mb-sm-5 mb-4"
    :class="{ AmazingOffer: type == 'AmazingOffer' }"
  >
    <div class="d-flex flex-wrap justify-content-center align-items-baseline">
      <div class="linkeAndtitleHome recommended-products-category d-flex">
        <ul class="nav nav-tabs">
          <template>
            <li class="nav-item ml-1 " role="presentation">
              <a
                class="nav-link py-1 px-md-3 px-2 fontsize-large fontsize-md-17 bg-none active"
                id="home-tab"
                aria-controls="home"
                aria-selected="true"
                >{{ productTitle }}</a
              >
            </li>
          </template>
        </ul>
        <!-- <div class="line d-none d-xl-block"></div> -->

        <router-link :to="link" class="seeMoreHomeLink">مشاهده همه</router-link>
      </div>
    </div>
    <div class="recommended-content mt-2 mt-sm-4">
      <div class="recommended-content-item position-relative" id="home">
        <!-- <div :style="`min-height: ${minHeight}px`"> -->
        <div>
          <div
            class="swiper-container"
            v-if="!disabledSliders.includes('recommendedProductSlider')"
          >
            <div class="swiper-wrapper">
              <Product
                class="swiper-slide"
                :style="'max-width:' + maxWidth + 'px'"
                v-for="(product, index) in products"
                :key="index"
                :product="product"
              />
            </div>
          </div>
        </div>
        <template v-if="type != 'AmazingOffer'">
          <div class="swiper-button-next">
            <ZArrowBold style="transform: rotate(180deg)" />
          </div>
          <div class="swiper-button-prev">
            <ZArrowBold />
          </div>
        </template>
      </div>
    </div>
    <template v-if="type == 'AmazingOffer'">
      <div class="next-btn-holder">
        <div class="next-btn">
          <div class="next-btn-icon"><RightChevIcon /></div>
        </div>
      </div>
      <div class="prev-btn-holder">
        <div class="prev-btn">
          <div class="prev-btn-icon"><LeftChevIcon /></div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import { getImage } from "@/libraries/functions";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";
import LeftChevIcon from "@/parts/Front/components/shared/LeftChevIcon.vue";
import RightChevIcon from "@/parts/Front/components/shared/RightChevIcon.vue";

// let breakpoints = {
//   300: {
//     slidesPerView: 2,
//     spaceBetween: 10,
//   },
//   500: {
//     slidesPerView: 2,
//     spaceBetween:15,
//   },
//   992: {
//     slidesPerView: 4,
//     spaceBetween: 1,
//   },
//   1200: {
//     slidesPerView: 5,
//     spaceBetween: 10,
//   },
// };
let breakpoints = {
  300: {
    slidesPerView: 1.3,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 10,
  },
};
export default {
  name: "RecommendedProductSlider",
  components: { ZArrowBold, Product, LeftChevIcon, RightChevIcon },
  props: {
    productTitle: String,
    link: String,
    products: Array,
    uniqId: String,
    type: String,
  },
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",
      activeTab: "new_products",
      tabs: [
        {
          name: "new_products",
          label: "جدیدترین محصولات",
        },
      ],
      loadedTabs: ["new_products"],
      sliderIsLoading: true,
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    activeTab: {
      handler: function() {
        this.initSlider(true);
      },
    },
  },
  mounted() {
    this.initSlider(false);
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
    window.addEventListener("resize", this.onResize2);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    showCount() {
      return 16;
    },

    new_products() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return Array(this.showCount).fill(null);
      } else {
        return this.$store.getters["front/getHomeData"].new_products;
      }
    },
  },
  methods: {
    hasAny(name) {
      return this[name] && this[name].length;
    },
    getProductMainImageUrl(product) {
      return getImage(product.major_image, "lg");
    },
    onResize() {
      this.setMaxWidth();
    },
    onResize2() {
      let brandsSection = document.querySelector(".asdasdsa");
      // let brandsSection = document.querySelector(".AmazingOffer .recommended-content");
      let bodyWidth = document.body.offsetWidth;
      if (brandsSection) {
        if (bodyWidth > 1200) {
          console.log(bodyWidth);
          // console.log((Number(bodyWidth) / (Number(bodyWidth) - Number(bodyWidth-130) )));
          brandsSection.style.height = bodyWidth / 3.5 + "px";
        } else if (bodyWidth < 1200 && bodyWidth > 840) {
          brandsSection.style.height = bodyWidth / 2.5 + "px";
        } else if (bodyWidth > 767 && bodyWidth < 840) {
          brandsSection.style.height = bodyWidth / 2.5 + "px";
        } else if (bodyWidth < 768 && bodyWidth > 600) {
          brandsSection.style.height = bodyWidth / 2.5 + "px";
        } else if (bodyWidth < 630 && bodyWidth > 550) {
          brandsSection.style.height = bodyWidth / 1.5 + "px";
        } else if (bodyWidth < 550 && bodyWidth > 300) {
          brandsSection.style.height = bodyWidth / 1.2 + "px";
        } else {
          brandsSection.style.height = bodyWidth / 1 + "px";
        }
      }
    },
    loadTab(name) {
      if (this.loadedTabs.includes(name)) {
        return;
      }
      this.loadedTabs.push(name);
      this[name].forEach((product) => {
        if (!product) return;
        let url = this.getProductMainImageUrl(product);
        let img = new Image();
        img.src = url;
      });
    },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector(".recommended-products").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("recommendedProductSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(`#${this.uniqId} .swiper-container`, {
          spaceBetween: 20,
          //   centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: false,
          navigation: {
            nextEl: `#${this.uniqId} .swiper-button-next`,
            prevEl: `#${this.uniqId} .swiper-button-prev`,
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document.querySelector(
            `#${this.uniqId} .swiper-container`
          )
            ? document
                .querySelector(`#${this.uniqId} .swiper-container`)
                .getBoundingClientRect().height
            : 0;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.swiper-container {
  animation: fade 450ms;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
}
.nav-link {
  font-weight: 900 !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  background: var(--color-theme);
  height: 38px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev {
  right: -10px;
  transform: translateY(-40px);
}
.swiper-button-next {
  left: -10px;
  right: unset;
  transform: translateY(-40px);
}

.nav-link {
  color: #696969 !important;
}
.nav-link:hover {
  color: white !important;
}

/*new*/
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.AmazingOffer {
  background-color: rgba(243, 60, 91, 1);
  border-radius: 50px;
  padding: 0 40px 0 40px;
  position: relative;
  margin-bottom: 142px !important;
}
.AmazingOffer .swiper-slide {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.AmazingOffer .recommended-content {
  position: relative;
  top: 80px;
}
.AmazingOffer .swiper-container {
  animation: fade 450ms;
}
.AmazingOffer .nav-item {
  border-radius: 25px;

  white-space: nowrap;
}
.AmazingOffer .recommended-products-tabs {
  position: relative;
  top: 40px;
  right: 0;
}
.AmazingOffer .prev-btn-holder {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateX(1px);
}
.AmazingOffer .next-btn-holder {
  position: absolute;
  top: 50%;
  left: 100%;
}
.AmazingOffer .prev-btn {
  background-color: rgba(243, 60, 91, 1);
  height: 140px;
  width: 120px;
  position: relative;
  -webkit-clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
  clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
}
.AmazingOffer .prev-btn-icon {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}
.AmazingOffer .next-btn {
  background-color: rgba(243, 60, 91, 1);
  position: relative;
  height: 140px;
  width: 120px;
  -webkit-clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
  clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
}
.AmazingOffer .next-btn-icon {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}
.AmazingOffer .line {
  width: 60%;
  height: 1px;
  border-radius: 25px;
  background-color: #ffffff;
  flex-grow: 1;
}
.seeMoreHomeLink {
  border: 1px solid #000 !important;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 14px !important;
  transition: all 0.3s;
}
.seeMoreHomeLink:hover {
  background-color: #fff;
  color: #000;
}
.AmazingOffer .seeMoreHomeLink {
  color: #fff;
}
.AmazingOffer .seeMoreHomeLink {
  border-bottom: 2px solid #fff !important;
}
@media (max-width: 1199px) {
  .AmazingOffer .recommended-products {
    border-radius: unset;
    padding: 0;
  }
  .AmazingOffer .recommended-content {
    position: relative;
    top: 50px;
    padding-inline: 20px;
  }
}
</style>
<style>
.nav-link.active {
  color: black !important;
  font-weight: bold;
  font-size: 1.1rem !important;
  position: relative;
}

.nav-link.active:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  width: 50%;
  height: 2px;
  background: var(--color-line);
  opacity: 0.7;
}

.recommended-products-more {
  background: var(--color-theme2);
}

.recommended-products-more:hover {
  background: #000;
  color: white;
}

/*new*/

.AmazingOffer .nav-link.active {
  font-weight: bold;
  color: white !important;

  /* border-radius: 25px !important;*/
  font-size: 1.1rem !important;
}
.AmazingOffer .nav-link.active:hover {
  color: white !important;

  /* border-radius: 25px !important;*/
  font-size: 1.1rem !important;
}
.AmazingOffer .swiper-button-next {
  left: -50px;
}
.AmazingOffer .swiper-button-prev {
  right: -70px;
}
</style>
