import { render, staticRenderFns } from "./productsSlider.vue?vue&type=template&id=939a490a&scoped=true&"
import script from "./productsSlider.vue?vue&type=script&lang=js&"
export * from "./productsSlider.vue?vue&type=script&lang=js&"
import style0 from "./productsSlider.vue?vue&type=style&index=0&id=939a490a&scoped=true&lang=css&"
import style1 from "./productsSlider.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939a490a",
  null
  
)

export default component.exports