<template>
  <nav
    style="vertical-align: middle"
    class="d-lg-inline-block d-none main-menu navbar-expand fontsize14"
  >
    <b-row id="menuSkelton" v-if="status">
      <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
      <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
      <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
      <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
      <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
    </b-row>
    <ul v-else class="navbar-nav h-100 pt-2 mr-3">
      <li
        v-for="item in menu"
        :key="item.id"
        :class="{ 'has-children': item.children.length != 0 }"
        class="nav-item text-center fontsize-mini"
      >
        <a
          v-if="item.link"
          class="nav-link d-inline-block weight-bold"
          :target="item.new_tab ? '_blank' : '_self'"
          :href="
            item.link
              ? item.link
              : $router.resolve(
                  item.linkable_type == 'Modules\\Category\\Entities\\Category'
                    ? findRoute(item.linkable_id)
                    : findUrl(
                        item.linkable_type,
                        item.linkable_id,
                        slugGenerator(item),
                        item.link
                      )
                ).href
          "
        >
          {{ item.title }}
          <span v-if="item.children.length != 0">
            <svg
              width="11"
              height="11"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.5L12 15.5L5 8.5"
                stroke="#444"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </a>
        <router-link
          v-else
          :target="item.new_tab ? '_blank' : '_self'"
          class="nav-link d-inline-block weight-bold"
          :to="
            item.linkable_type == 'Modules\\Category\\Entities\\Category'
              ? findRoute(item.linkable_id)
              : findUrl(
                  item.linkable_type,
                  item.linkable_id,
                  slugGenerator(item)
                )
          "
        >
          {{ item.title }}
          <span v-if="item.children.length != 0">
            <svg
              width="11"
              height="11"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.5L12 15.5L5 8.5"
                stroke="#444"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </router-link>
        <ul
          v-if="item.children.length != 0"
          class=" dropdown-menu-hover list-unstyled justify-content-center align-items-center bg-white"
        >
          <li
            v-for="childMenu in item.children"
            :class="{ 'has-child-children': childMenu.children.length != 0 }"
            :key="childMenu.id"
            class="nav-item"
          >
            <a
              :target="childMenu.new_tab ? '_blank' : '_self'"
              class="nav-link"
              v-if="childMenu.link"
              :href="
                childMenu.link
                  ? childMenu.link
                  : $router.resolve(
                      childMenu.linkable_type ==
                        'Modules\\Category\\Entities\\Category'
                        ? findRoute(childMenu.linkable_id)
                        : findUrl(
                            childMenu.linkable_type,
                            childMenu.linkable_id,
                            slugGenerator(childMenu),
                            childMenu.link
                          )
                    ).href
              "
              >{{ childMenu.title }}
              <span v-if="childMenu.children.length != 0">
                <i
                  style="position: relative; top: 2px"
                  class="fa fa-angle-left"
                ></i>
              </span>
            </a>
            <router-link
              v-else
              :target="childMenu.new_tab ? '_blank' : '_self'"
              class="nav-link"
              :to="
                childMenu.linkable_type ==
                'Modules\\Category\\Entities\\Category'
                  ? findRoute(childMenu.linkable_id)
                  : findUrl(
                      childMenu.linkable_type,
                      childMenu.linkable_id,
                      slugGenerator(childMenu)
                    )
              "
              >{{ childMenu.title }}
              <span v-if="childMenu.children.length != 0">
                <i
                  style="position: relative; top: 2px"
                  class="fa fa-angle-left"
                ></i>
              </span>
            </router-link>
            <ul
              v-if="childMenu.children.length != 0"
              class="
                dropdown-child-menu-hover list-unstyled
                justify-content-center align-items-center bg-white"
            >
              <li
                :class="{
                  'has-child-children': childrenMenu.children.length != 0,
                }"
                v-for="childrenMenu in childMenu.children"
                :key="childrenMenu.id"
                class="nav-item"
              >
                <a
                  v-if="childrenMenu.link"
                  class="nav-link childMenu"
                  :target="childMenu.new_tab ? '_blank' : '_self'"
                  :href="
                    childrenMenu.link
                      ? childrenMenu.link
                      : $router.resolve(
                          childrenMenu.linkable_type ==
                            'Modules\\Category\\Entities\\Category'
                            ? findRoute(childrenMenu.linkable_id)
                            : findUrl(
                                childrenMenu.linkable_type,
                                childrenMenu.linkable_id,
                                slugGenerator(childrenMenu),
                                childrenMenu.link
                              )
                        ).href
                  "
                  >{{ childrenMenu.title }}
                  <span v-if="childrenMenu.children.length != 0">
                    <i
                      style="position: relative; top: 2px"
                      class="fa fa-angle-left"
                    ></i>
                  </span>
                </a>
                <router-link
                  v-else
                  :target="childMenu.new_tab ? '_blank' : '_self'"
                  class="nav-link childMenu"
                  :to="
                    childrenMenu.linkable_type ==
                    'Modules\\Category\\Entities\\Category'
                      ? findRoute(childrenMenu.linkable_id)
                      : findUrl(
                          childrenMenu.linkable_type,
                          childrenMenu.linkable_id,
                          slugGenerator(childrenMenu)
                        )
                  "
                  >{{ childrenMenu.title }}
                  <span v-if="childrenMenu.children.length != 0">
                    <i
                      style="position: relative; top: 2px"
                      class="fa fa-angle-left"
                    ></i>
                  </span>
                </router-link>
                <!-- THIRD MENU -->
                <ul
                  v-if="childrenMenu.children.length != 0"
                  class="
                dropdown-child-child-menu-hover list-unstyled
                justify-content-center align-items-center bg-white"
                >
                  <li
                    v-for="childrenChildMenu in childrenMenu.children"
                    :key="childrenChildMenu.id"
                    class="nav-item"
                  >
                    <a
                      v-if="childrenChildMenu.link"
                      class="nav-link childMenu"
                      :href="childrenChildMenu.link"
                      >{{ childrenChildMenu.title }}</a
                    >
                    <router-link
                      v-else
                      class="nav-link childMenu"
                      :to="
                        childrenChildMenu.linkable_type ==
                        'Modules\\Category\\Entities\\Category'
                          ? findRoute(childrenChildMenu.linkable_id)
                          : findUrl(
                              childrenChildMenu.linkable_type,
                              childrenChildMenu.linkable_id,
                              slugGenerator(childrenChildMenu)
                            )
                      "
                      >{{ childrenChildMenu.title }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import { BRow, BSkeleton } from "bootstrap-vue";

export default {
  name: "Nav",
  components: {
    BRow,
    BSkeleton,
  },
  computed: {
    menu() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].menu.header;
      } else {
        return this.$store.getters["front/getHomeData"];
      }
    },
    categories() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getSortedCategories"];
      } else {
        return this.$store.getters["front/getHomeData"];
      }
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  methods: {
    findUrl,
    findRoute(id) {
      let route = "/category/";
      this.categories.forEach((cat) => {
        if (cat.id == id) {
          route += cat.en_title ? cat.en_title : cat.id;
          return;
        } else if (cat.children && cat.children.length) {
          cat.children.forEach((subCat) => {
            if (subCat.id == id) {
              route += subCat.en_title ? subCat.en_title : subCat.id;
              return;
            } else if (subCat.children && subCat.children.length) {
              subCat.children.forEach((subsubCat) => {
                if (subsubCat.id == id) {
                  route += subsubCat.en_title
                    ? subsubCat.en_title
                    : subsubCat.id;
                  return;
                }
              });
            }
          });
        }
      });
      return route;
    },

    slugGenerator(item) {
      return item.slug ? item.slug : item.title.replace(" ", "-");
    },
  },
};
</script>

<style scoped>
.childMenu {
  color: #666;
}
#menuSkelton {
  display: flex;
  justify-content: space-around;
  /*width: 300px;*/
}
/*#menuSkelton > div{*/
/*  margin-right: 50px;*/
/*}*/

.navbar-nav {
  gap: 20px;
}
.nav-item {
  position: relative;
}
.main-menu.navbar-expand > .navbar-nav > .nav-item:hover:before {
  content: "";
  height: 2px;
  width: 100%;
  background: var(--color-theme3);
  position: absolute;
  bottom: 4px;
}
</style>
