<template>
  <div>
    <section
      @open="opened"
      class="main-search bg-white box-shaddow20 text-right"
    >
      <button
        class="rounded-circle close-search bg-none"
        @click="showCloseSearchBox()"
      >
        <span class="text-color-999" style="font-size: 48px"> &times; </span>
      </button>
      <div id="searchBox" class="container d-flex pb-5 pt-2">
        <div
          id="searchForm"
          class="search-form d-flex w-100 justify-content-between"
        >
          <input
            id="searchInput"
            :value="search"
            @input="(e) => (search = e.target.value)"
            type="text"
            @keypress.enter="gotoSearchPage"
            @click.prevent="focusInput()"
            class="w-100"
            placeholder="جستجو کنید..."
          />
          <button
            @click.prevent="gotoSearchPage"
            type="button"
            class="bg-none ml-2"
          >
            <svg
              width="27"
              height="27"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.7666"
                cy="11.7666"
                r="8.98856"
                stroke="#444"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.0183 18.4851L21.5423 22"
                stroke="#444"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div id="spinnerSearch" v-show="status">
          <div class="hollow-dots-spinner">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
        <div v-if="showResult">
          <div v-if="products">
            <div id="tableBox" v-if="products != null && products.length != 0">
              <table class="table">
                <tbody>
                  <tr
                    class="searchItem"
                    v-for="result in products"
                    :key="result.id"
                    @click="showProduct(result)"
                  >
                    <th scope="row">
                      <img
                        class="resultImage"
                        v-if="result.major_image != null"
                        :src="result.major_image.url"
                        :alt="result.title"
                      />

                      <div v-else class="notImage"></div>
                    </th>
                    <td class="shortDescSearch">
                      <span>{{ result.title }}</span>
                      <span class="searchPrice"
                        >{{ result.price | price }} تومان</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="products.length == 8" id="showMoreDiv">
                <button id="showMoreBtn" @click.prevent="gotoSearchPage">
                  مشاهده بیشتر
                  <i class="fa fa-angle-down"></i>
                </button>
              </div>
            </div>
            <div
              id="notFoundProduct"
              v-if="
                (products == null || products.length == 0) && searching == false
              "
            >
              نتیجه ایی پیدا نشد
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="main-search-close-resp" @click="mainSearchCloseBody()"></div>
    <!-- close search by click to body-->
  </div>
</template>

<script>
import { openSearch } from "../../../libraries/functions";

export default {
  name: "MainSearch",
  data() {
    return {
      searchList: [],
      search: "",
      showResult: false,
      searching: false,
    };
  },
  methods: {
    opened() {
      this.search = "";
    },
    showCloseSearchBox() {
      openSearch();
    },
    mainSearchCloseBody() {
      this.showResult = false;
      let tableBox = document.getElementById("tableBox");
      if (tableBox) {
        tableBox.style.display = "none";
      }
      const searchBox = document.querySelectorAll(".main-search")[0];
      let mainSearchResp = document.querySelectorAll(
        ".main-search-close-resp"
      )[0];
      searchBox.classList.toggle("show-main-search");
      mainSearchResp.style.display = "none";
    },
    // نمایش محصول بعد از سرچ و کلیک بروی نمایش
    showProduct(product) {
      this.mainSearchCloseBody();
      this.showResult = false;
      setTimeout(() => {
        this.$router.push({
          name: "product.detail",
          params: {
            name: `${product.en_title ? product.en_title.replaceAll(' ','-') + "-" : "detail-"}${
              product.id
            }`,
          },
        });
      }, 1000);
    },
    // این متد برای زمانی که بروی اینپوت کلیک میشود فراخوانی میشود
    focusInput() {
      if (this.products != null) {
        this.showResult = true;
      }
    },
    // جستجو در میان لیست محصولات بعد از پایان نوشتن مشتری
    searchRequest() {
      if (this.search != "" && this.search.length > 1) {
        this.showResult = true;
        this.searching = true;
        this.$store.dispatch("front/searchInProducts", this.search);
      }
    },
    // تابع با فشردن اینتر به صفحه محصولات هدایت میکند
    gotoSearchPage() {
      let search = this.search;
      if (this.$route.name == "products") {
        this.search = "";
        this.mainSearchCloseBody();
        this.$router.push({ name: "products", query: { title: search } });
      } else {
        return this.$router.push({
          name: "products",
          query: { title: search },
        });
      }
    },
  },
  watch: {
    status(val) {
      if (val == false) {
        this.searching = false;
      }
    },
    search(newVal) {
      // تماشا کردن تغییرات اینپوت سرچ و اگر بعد از اخرین حرف وارده حرفی وارد نکند سرچ انجام میشود
      this.searchList.push(newVal);
      let last = this.searchList.length;
      setTimeout(() => {
        if (this.searchList[last] == undefined) {
          this.searchList = [];
          this.searchRequest();
        }
      }, 1500);
    },
  },
  computed: {
    // استاتوس تغییر وضعیت برای نمایش لودینگ
    status() {
      return this.$store.getters["front/getSearchStatus"];
    },
    // دریافت محصولات پیدا شده از ویو ایکس
    products() {
      return this.$store.getters["front/getSearchResult"];
    },
  },
};
</script>

<style scoped>
/* اسپینر */
.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid var(--color-theme3);
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* کدهای قسمت جستوجو و بخش های اضافه شده */
#searchBox {
  flex-direction: column;
}
#spinnerSearch {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
#tableBox {
  max-height: 300px;
  overflow: auto;
}
.notImage {
  width: 60px;
  height: 50px;
  background-color: silver;
}
.resultImage {
  cursor: pointer;
  max-width: 60px;
  max-height: 75px;
  object-fit: fill;
}
#searchInput {
  border-bottom: 1px solid #c7c7c7;
}
#showMoreDiv {
  display: flex;
  justify-content: center;
}
#showMoreBtn {
  background-color: transparent;
}
#showMoreBtn i {
  position: relative;
  top: 4px;
}
#notFoundProduct {
  position: relative;
  top: 15px;
  text-align: center;
}
.searchItem {
  cursor: pointer;
}
.shortDescSearch {
  flex-direction: column;
  vertical-align: bottom;
  height: 100px;
  display: flex;
  justify-content: center;
}
.searchPrice {
  color: #ff2d2d;
}

table tr > td:nth-child(1),
table tr > th:nth-child(1) {
  width: 86px;
}
</style>
