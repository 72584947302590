<template>
  <div>
    <section class="new-top-slider">
      <div class="swiper-container h-100">
        <div class="swiper-wrapper">
         <template v-if="mobile">
          <div class="swiper-slide bg-color-ea" >
            <router-link class="top-slider-img" to="/products">
              <img
                src="@/assets/images/sliders/mobile-slider1.png"
                class="w-100 h-100"
                alt="carousel image"
              />
            </router-link>
          </div>
          <div class="swiper-slide bg-color-ea" >
            <router-link class="top-slider-img" to="/products">
              <img
                src="@/assets/images/sliders/mobile-slider1.png"
                class="w-100 h-100"
                alt="carousel image"
              />
            </router-link>
          </div>
         </template>
          <template>
            <!-- <div class="swiper-slide bg-color-ea " >
              <router-link class="top-slider-img" to="/products">
                <img
                  src="@/assets/images/SajadCameron_yalda.jpg"
                  class="w-100 h-100"
                  alt="carousel image"
                />
              </router-link>
            </div> -->
            <div class="swiper-slide bg-color-ea " >
              <router-link class="top-slider-img" to="/products">
                <img
                  src="@/assets/images/sliders/slider1.png"
                  class="w-100 h-100"
                  alt="carousel image"
                />
              </router-link>
            </div>
            <div class="swiper-slide bg-color-ea " >
              <router-link class="top-slider-img" to="/products">
                <img
                  src="@/assets/images/sliders/slider2.png"
                  class="w-100 h-100"
                  alt="carousel image"
                />
              </router-link>
            </div>
          </template>
        </div>
        <!-- Add Pagination -->
        <!-- <div class="d-md-none swiper-pagination"></div> -->
        <!-- Add Arrows -->
      </div>
      <div class="d-md-block d-none swiper-button-next text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            fill="#999"
          />
        </svg>
      </div>
      <div class="d-md-block d-none swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            fill="#999"
          />
        </svg>
      </div>
    </section>
  </div>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min.js";
export default {
  name: "homeSlider",
  inject: ["mediaQueries"],
  methods: {
    onResize() {
      if (window.innerWidth > 768) {
        this.mobile = false;
      } else {
        this.mobile = true;
      }
      let topSliderH = document.querySelector(".new-top-slider");
      let bodyWidth = document.body.offsetWidth;
      if (topSliderH) {
        if (bodyWidth < 767) {
          topSliderH.style.height = bodyWidth / 2 + "px";
        } else {
          topSliderH.style.height = bodyWidth / 3 + "px";
        }
      }
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy();
          }
        } catch (e) {}

        this.slider = new Swiper(".new-top-slider .swiper-container", {
          spaceBetween: 10,
          speed: 1000,
          loop: false,
          autoplay: {
            delay: 8000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".new-top-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".new-top-slider .swiper-button-next",
            prevEl: ".new-top-slider .swiper-button-prev",
          },
        });
      });
      this.$nextTick(() => {
        this.onResize();
      });
    },
  },
  mounted() {
    // ست کردن تنظیمات  برای اسکرول صفحه در برابر رسپانسیو
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
    this.initHeaderSwiper();
    //CHange height of new-top-slider
    const topSliderH = document.querySelector(".new-top-slider");
    const bodyWidth = document.body.offsetWidth;
    if (topSliderH) {
      if (bodyWidth < 767) {
        topSliderH.style.height = bodyWidth / 1.6 + "px";
      } else {
        topSliderH.style.height = bodyWidth / 2.2 + "px";
      }
    }
    this.mobile = window.innerWidth > 768 ? false : true;
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  },
  data() {
    return {
      mobile:false,
    };
  },
};
</script>
<style scoped>
.swiper-button-next:after,
.swiper-button-prev:after {
  display: none !important;
}
</style>
