<template>
  <div>
    <!-- <WhatsappContact /> -->
    <div>
      <Header class="paddingtb10" />
      <router-view :key="$route.path"></router-view>
      <Footer />

      <FooterButtons></FooterButtons>
    </div>
    <div id="mainSpinner" v-show="status">
      <div v-if="status" class="flower-spinner">
        <div class="dots-container">
          <div class="bigger-dot">
            <div class="smaller-dot"></div>
          </div>
        </div>
      </div>
    </div>
    <Compare @entered="showCompareContent = true" />
    <transition name="fade">
      <CompareContent
        @left="showCompareContent = false"
        v-if="
          !mediaQueries.mobileSize &&
            Boolean(compares.length) &&
            showCompareContent
        "
      />
    </transition>
    <!-- <div id="agentsModal" @click="closeModal" v-if="agentsModal">
      <div id="content">
        <div class="h_iframe-aparat_embed_frame">
          <span style="display: block;padding-top: 57%"></span
          ><iframe
            src="https://www.aparat.com/video/video/embed/videohash/chw7570/vt/frame"
            allowFullScreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import "bootstrap";
import Header from "@/parts/Front/components/shared/HeaderSecond";
import Footer from "@/parts/Front/components/shared/Footer";
import FooterButtons from "@/parts/Front/components/FooterButtons";
import Compare from "@/parts/Front/components/shared/Compare";
import CompareContent from "@/parts/Front/components/shared/CompareContent";
import Whatsapp from "../nicecomponents/svgs/Whatsapp.vue";
// import WhatsappContact from "../components/WhatsappContact";
import { getCookie, setCookie } from "../../../libraries/VueMethods";
//   در هر کامپوننت میتوانیم از این متغییر استفاده کنیم نحوه استفاده در کامپوننت زیر
// RespLoginModal
export default {
  name: "Home",
  components: {
    // WhatsappContact,
    Header,
    Footer,
    FooterButtons,
    Compare,
    CompareContent,
    Whatsapp,
  },
  inject: ["mediaQueries"],
  data() {
    return {
      phone: null,
      showCompareContent: false,
      disableButton: false,
      agentsModal: false,
    };
  },
  mounted() {
    // this.handlerAgentsModal(); 
  },
  methods: {
    // closeModal(e) {
    //   if (e && e.target.id == "agentsModal") {
    //     this.agentsModal = false;

    //     setCookie("agentsModal", true);
    //   }
    // },
    // handlerAgentsModal() {
    //   if (!getCookie("agentsModal")) {
    //     this.agentsModal = true;

    //     setCookie("agentsModal", true);
    //   }
    // },
    sendNumber() {
      // text: "این شماره باید 11 رقمی باشد",

      if (!this.phone) {
        window
          .swal({
            title: "شماره تماس خود را به درستی وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.phone.focus();
          });
      } else if (this.phone.length != 11) {
        window
          .swal({
            title: "این شماره باید 11 رقمی باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.phone.focus();
          });
      } else {
        setCookie("userSendedNumberModal", true, 3);

        this.disableButton = true;
        const formData = new FormData();
        formData.append("mobile", this.phone);
        if (this.$route.query.source) {
          formData.append("source", this.$route.query.source);
        }
        this.$axios
          .post("front/amazing_newsletters", formData)
          .then((res) => {
            window
              .swal({
                title: res.data.message,
                icon: "success",

                showConfirmButton: true,
                dangerMode: true,
                button: "متوجه شدم",
              })
              .then(() => {
                this.$refs.phone.focus();
              });
            this.showModal = false;
            this.disableButton = false;
          })
          .catch((error) => {
            window
              .swal({
                title: "شماره موبایل وارد شده تکراری است",
                icon: "success",

                showConfirmButton: true,
                dangerMode: true,
                button: "متوجه شدم",
              })
              .then(() => {
                this.$refs.phone.focus();
              });
            this.disableButton = false;
          });
      }
    },
  },
  computed: {
    windowSize() {
      return window.innerWidth;
    },
    showNumberModal() {
      if (!getCookie("userSendedNumberModal") && this.showModal == true) {
        return true;
      }
      return false;
    },
    content() {
      let homeData = this.$store.getters["front/getHomeData"];
      if (homeData) {
        return homeData.settings;
      }
      return null;
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    compares() {
      console.log(this.$store.getters["front/getCompares"]);
      return this.$store.getters["front/getCompares"];
    },
  },
  created() {
    this.$store.dispatch("front/getHomeDataFromServer", {
      $root: this.$root,
    });
  },
  metaInfo() {
    return {
      title:
        this.content && this.content.customer_setting
          ? this.content.customer_setting.title
          : "",
      meta: [
        {
          name: "description",
          content: this.blog ? this.blog.post.meta_description : false,
        },
        {
          property: "og:title",
          content: this.blog ? this.blog.post.title : false,
        },
      ],
    };
  },
};
</script>
<style scoped>
.paddingtb10 {
  padding: 10px 0;
}
/* اسپینر استایل */
#mainSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  background: rgba(138, 114, 114, 0.72);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flower-spinner,
.flower-spinner * {
  box-sizing: border-box;
}

.flower-spinner {
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flower-spinner .dots-container {
  height: calc(70px / 7);
  width: calc(70px / 7);
}

.flower-spinner .smaller-dot {
  background: var(--color-theme);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: flower-spinner-smaller-dot-animation 2.5s 0s infinite both;
}

.flower-spinner .bigger-dot {
  background: var(--color-theme);
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
  animation: flower-spinner-bigger-dot-animation 2.5s 0s infinite both;
}

@keyframes flower-spinner-bigger-dot-animation {
  0%,
  100% {
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }

  50% {
    transform: rotate(180deg);
  }

  25%,
  75% {
    box-shadow: var(--color-theme) 26px 0px 0px,
      var(--color-theme) -26px 0px 0px, var(--color-theme) 0px 26px 0px,
      var(--color-theme) 0px -26px 0px, var(--color-theme) 19px -19px 0px,
      var(--color-theme) 19px 19px 0px, var(--color-theme) -19px -19px 0px,
      var(--color-theme) -19px 19px 0px;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }
}

@keyframes flower-spinner-smaller-dot-animation {
  0%,
  100% {
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }

  25%,
  75% {
    box-shadow: var(--color-theme) 14px 0px 0px,
      var(--color-theme) -14px 0px 0px, var(--color-theme) 0px 14px 0px,
      var(--color-theme) 0px -14px 0px, var(--color-theme) 10px -10px 0px,
      var(--color-theme) 10px 10px 0px, var(--color-theme) -10px -10px 0px,
      var(--color-theme) -10px 10px 0px;
  }

  100% {
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }
}

a#whatsAppSupport svg {
  width: 100px;
  height: 50px;
}
#whatsAppSupport {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 10000;
}
@media (max-width: 768px) {
  #whatsAppSupport {
    bottom: 80px;
  }
}

div#getNumberModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000007a;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#content button {
  padding: 8px 16px;
  border-radius: 4px;
  background: #32cf59;
  color: white;
}
div#content input {
  width: 90%;
  padding: 8px 16px;
  margin: auto;
  border-bottom: 1px solid #0064003d;
}
div#content p {
  text-align: center;
  line-height: 24px;
  font-size: 20px;
}
div#content img {
  width: 100%;
}

div#content #mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: absolute;
  right: 20%;
  top: 25%;
  color: white;
}
div#content {
  padding: 15px;
  border-radius: 15px;
  max-width: 80%;
  margin: auto;
  position: relative;
}
.disableButton {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.showInMobile {
  display: none;
}
@media (max-width: 900px) {
  .hiddenInMobile {
    display: none;
  }
  .showInMobile {
    display: block;
  }

  div#content #mainContent {
    align-items: center;
    right: 10%;
    top: 15%;
  }

  div#content p {
    width: 90%;
  }
  div#content input {
    width: 70%;
    margin: 0;
  }
}
@media (max-width: 768px) {
  div#content #mainContent {
    align-items: center;
    right: 6%;
    top: 13%;
  }
}
@media (max-width: 712px) {
  div#content #mainContent {
    align-items: center;
    right: 4%;
    top: 13%;
  }
  div#content p {
    width: 76%;
  }
}
@media (max-width: 680px) {
  div#content img {
    width: 100%;
    transform: rotate(90deg) scale(1.2, 1.8);
  }
}
@media (max-width: 540px) {
  div#content img {
    width: 100%;
    transform: rotate(90deg) scale(1.4, 2);
  }
}
@media (max-width: 712px) {
  div#content #mainContent {
    align-items: center;
    right: 4%;
    top: 0;
  }
}
.h_iframe-aparat_embed_frame {
  position: relative;
}
.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}
.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
div#agentsModal {
  position: fixed;
  inset: 0;
  z-index: 1000000;
  background: #191919f5;
  width: 100vw;
  height: 100vh;
}
@media (max-width: 580px) {
  div#agentsModal #content {
    margin-top: 50%;
  }
}
</style>
