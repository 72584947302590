<template>
  <div>
    <ProductWarranty></ProductWarranty>

    <footer class="footer-container bg-secondary-400 mt-3">
      <section class=" w-90 mr-auto pt-3 ml-auto " style="width: 84%;">
        <div
          class="d-flex justify-content-between align-items-center mt-2 top-footer"
        >
          <router-link to="/">
            <img
              class="logo-footer"
              src="@/assets/images/sajad-logo.png"
              alt=""
            />
          </router-link>
          <div class="line-footer"></div>
        </div>
        <div
          class="address-footer d-flex align-items-center flex-wrap mt-5"
          style="gap: 8px;"
        >
          <template v-if="!loading">
            <div
              class="d-flex align-items-center"
              style="gap: 8px;"
              v-if="homeData.settings.customer_setting.mobile"
            >
              <h6 class="text-color-999 fontsize-small d-flex m-0 p-0">
                شماره تماس :
              </h6>
              <span
                class="fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block"
              >
                {{ homeData.settings.customer_setting.mobile }}
              </span>
              <span class=" d-md-inline-block d-sm-none line-address-footer"
                >|</span
              >
            </div>
            <div
              class="d-flex align-items-center"
              style="gap: 8px;"
              v-if="homeData.settings.customer_setting.email"
            >
              <h6 class="text-color-999 fontsize-small d-flex m-0 p-0">
                آدرس ایمیل:
              </h6>
              <span
                class="fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block"
              >
                {{ homeData.settings.customer_setting.email }} </span
              ><span class="d-md-inline-block d-sm-none line-address-footer"
                >|</span
              >
            </div>
            <div
              class="d-flex align-items-center"
              style="gap: 8px;"
              v-if="homeData.settings.customer_setting.address"
            >
              <h6 class="text-color-999 fontsize-small d-flex m-0 p-0">
                آدرس فروشگاه:
              </h6>
              <span
                class="address-filde fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block"
              >
                {{ homeData.settings.customer_setting.address }}
              </span>
            </div>
            <div class="d-flex align-items-center">
              <span class="d-md-inline-block d-sm-none line-address-footer"
                >|</span
              >

              <h6 class="text-color-999 fontsize-small d-flex m-0 p-0 mr-lg-2">
                هفت روز هفته، از ساعت 8 الی 23 پاسخگوی سوالات شما هستیم.
              </h6>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center flex-wrap">
              <span
                ><b-skeleton width="105px" class="d-inline-block mr-2"
              /></span>
              <span
                ><b-skeleton width="105px" class="d-inline-block mr-2"
              /></span>
              <span
                ><b-skeleton width="105px" class="d-inline-block mr-2"
              /></span>
              <span
                ><b-skeleton width="105px" class="d-inline-block mr-2"
              /></span>
              <span
                ><b-skeleton width="105px" class="d-inline-block mr-2"
              /></span>
            </div>
          </template>
        </div>
        <div class="list-menu-footer mt-5">
          <div>
            <nav class="restemporary">
              <h6 class="text-color-444 fontsize-small font-weight-bold">
                راهنمای خرید
              </h6>
              <ul class="nav fontsize13" style="gap: 8px;">
                <template>
                  <li class="nav-item fontsize-mini">
                    <router-link class="nav-link text-dark" to="/payment-method"
                      >شیوه پرداخت</router-link
                    >
                  </li>
                  <li class="nav-item fontsize-mini">
                    <router-link class="nav-link text-dark" to="/rules"
                      >قوانین و مقررات</router-link
                    >
                  </li>
                  <li class="nav-item fontsize-mini">
                    <router-link
                      class="nav-link text-dark"
                      to="/sending-product"
                      >رویه ارسال کالا</router-link
                    >
                  </li>
                </template>
              </ul>
            </nav>
            <!-- v-else -->
            <details
              class="select-menu-footer w-100 bg-transparent border border-dark border-radius15"
            >
              <summary
                class="summary d-flex justify-content-between align-items-center cursor-pointer"
              >
                <span class="text-color-444 fontsize-md-16 font-weight-bold">
                  راهنمای خرید
                </span>

                <span>
                  <svg
                    class="d-flex"
                    id="icon-summery"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </summary>
              <ul class="nav fontsize13" style="gap: 8px;">
                <template>
                  <li class="nav-item fontsize-mini">
                    <router-link class="nav-link text-dark" to="/payment-method"
                      >شیوه پرداخت</router-link
                    >
                  </li>
                  <li class="nav-item fontsize-mini">
                    <router-link class="nav-link text-dark" to="/rules"
                      >قوانین و مقررات</router-link
                    >
                  </li>
                  <li class="nav-item fontsize-mini">
                    <router-link
                      class="nav-link text-dark"
                      to="/sending-product"
                      >رویه ارسال کالا</router-link
                    >
                  </li>
                </template>
              </ul>
            </details>
          </div>
          <div>
            <nav class="restemporary">
              <h6 class="text-color-444 fontsize-small font-weight-bold">
                دسترسی سریع
              </h6>
              <ul class="nav fontsize13" style="gap: 8px;">
                <template v-if="menus">
                  <li
                    v-for="(item, index) in menus"
                    :key="index"
                    class="nav-item fontsize-mini"
                  >
                    <a
                      v-if="item.link"
                      class="nav-link d-inline-block weight-bold"
                      :target="item.new_tab ? '_blank' : '_self'"
                      :href="
                        item.link
                          ? item.link
                          : $router.resolve(
                              findUrl(
                                item.linkable_type,
                                item.linkable_id,
                                slugGenerator(item),
                                item.link
                              )
                            ).href
                      "
                    >
                      {{ item.title }}
                      <span v-if="item.children.length != 0">
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 8.5L12 15.5L5 8.5"
                            stroke="#444"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </a>
                    <router-link
                      v-else
                      :target="item.new_tab ? '_blank' : '_self'"
                      class="nav-link d-inline-block weight-bold"
                      :to="
                        findUrl(
                          item.linkable_type,
                          item.linkable_id,
                          slugGenerator(item)
                        )
                      "
                    >
                      {{ item.title }}
                      <span v-if="item.children.length != 0">
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 8.5L12 15.5L5 8.5"
                            stroke="#444"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </router-link>
                  </li>
                </template>
              </ul>
            </nav>
            <!--  v-else -->
            <details
              class="select-menu-footer w-100 bg-transparent border border-dark border-radius15"
            >
              <summary
                class="summary d-flex justify-content-between align-items-center cursor-pointer"
              >
                <span class="text-color-444 fontsize-small font-weight-bold">
                  دسترسی سریع
                </span>

                <span>
                  <svg
                    class="d-flex"
                    id="icon-summery"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </summary>
              <ul class="nav fontsize13" style="gap: 8px;">
                <template>
                  <li class="nav-item fontsize-mini">
                    <router-link class="nav-link text-dark" to="/payment-method"
                      >شیوه پرداخت</router-link
                    >
                  </li>
                  <li class="nav-item fontsize-mini">
                    <router-link class="nav-link text-dark" to="/rules"
                      >قوانین و مقررات</router-link
                    >
                  </li>
                  <li class="nav-item fontsize-mini">
                    <router-link
                      class="nav-link text-dark"
                      to="/sending-product"
                      >رویه ارسال کالا</router-link
                    >
                  </li>
                </template>
              </ul>
            </details>
          </div>
          <div v-if="homeData.settings.customer_setting.presentBranch1">
            <!-- <nav class="restemporary">
            <h6 class="text-color-444 fontsize-small font-weight-bold">
              دسته بندی
            </h6>
            <ul class="nav fontsize13" style="gap: 8px;">
              
                <li class="nav-item fontsize-mini">
                  <router-link
                    class="nav-link text-dark"
                    to="products/category/36?category_id=36"
                    >مردانه</router-link
                  >
                </li>
                <li class="nav-item fontsize-mini">
                  <router-link
                    class="nav-link text-dark"
                    to="/products/category/37?category_id=37"
                    >زنانه</router-link
                  >
                </li>
                <li class="nav-item fontsize-mini">
                  <router-link
                    class="nav-link text-dark"
                    to="/products/category/38?category_id=38"
                    >کفش</router-link
                  >
                </li>
            </ul>
          </nav> -->
            <h6 class="text-color-444 fontsize-small font-weight-bold">
              آدرس فروشگاه های حضوری
            </h6>
            <ul class="nav fontsize13" style="gap: 8px;">
              <li
                v-if="homeData.settings.customer_setting.presentBranch1"
                class="nav-item fontsize-mini"
              >
                <svg
                  fill="#bbb"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="24"
                  viewBox="0 0 395.71 395.71"
                  xml:space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path
                        d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                      />
                    </g>
                  </g>
                </svg>
                <span>
                  {{ homeData.settings.customer_setting.presentBranch1 }}
                </span>
              </li>
              <li
                v-if="homeData.settings.customer_setting.presentBranch2"
                class="nav-item fontsize-mini"
              >
                <svg
                  fill="#bbb"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="24"
                  viewBox="0 0 395.71 395.71"
                  xml:space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path
                        d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                      />
                    </g>
                  </g>
                </svg>
                <span>
                  {{ homeData.settings.customer_setting.presentBranch2 }}
                </span>
              </li>
              <li
                v-if="homeData.settings.customer_setting.presentBranch3"
                class="nav-item fontsize-mini"
              >
                <svg
                  fill="#bbb"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24"
                  height="24"
                  viewBox="0 0 395.71 395.71"
                  xml:space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path
                        d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                      />
                    </g>
                  </g>
                </svg>
                <span>
                  {{ homeData.settings.customer_setting.presentBranch3 }}
                </span>
              </li>
            </ul>
          </div>
          <div class="d-flex flex-column justify-content-between ">
            <section
              class="sotial-networks d-flex flex-column justify-content-between h-100"
            >
              <div>
                <h6 class="text-color-444 fontsize-small font-weight-bold">
                  با ما همراه باشید
                </h6>
                <template v-if="!loading">
                  <div class="mt-3">
                    <a
                      :href="homeData.settings.customer_setting.instagram"
                      class="mx-2 d-inline-block social-icon-footer"
                    >
                      <svg
                        width="17"
                        height="17"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        style="enable-background: new 0 0 512 512"
                        xml:space="preserve"
                      >
                        <path
                          d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                    C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                    h192c61.76,0,112,50.24,112,112V352z"
                          fill="#bbb"
                        />
                        <path
                          d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                    c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                          fill="#bbb"
                        />
                        <circle cx="393.6" cy="118.4" r="17.056" fill="#bbb" />
                      </svg>
                    </a>
                  </div>
                </template>
                <template v-else>
                  <span
                    ><b-skeleton
                      width="26px"
                      height="26px"
                      style="border-radius: 50%;"
                      class="d-inline-block mr-2"
                  /></span>
                  <span
                    ><b-skeleton
                      width="26px"
                      height="26px"
                      style="border-radius: 50%;"
                      class="d-inline-block mr-2"
                  /></span>
                  <span
                    ><b-skeleton
                      width="26px"
                      height="26px"
                      style="border-radius: 50%;"
                      class="d-inline-block mr-2"
                  /></span>
                  <span
                    ><b-skeleton
                      width="26px"
                      height="26px"
                      style="border-radius: 50%;"
                      class="d-inline-block mr-2"
                  /></span>
                  <span
                    ><b-skeleton
                      width="26px"
                      height="26px"
                      style="border-radius: 50%;"
                      class="d-inline-block mr-2"
                  /></span>
                </template>
              </div>
              <div class="mt-5">
                <h6 class="text-color-444 fontsize-small font-weight-bold">
                  از جدیدترین تخفیف‌ها باخبر شوید
                </h6>
                <div class="mt-3">
                  <form
                  @submit.prevent="joinUs()"
                    id="sendEmailForm"
                    class="w-100 d-flex justify-content-between align-items-center  pr-1"
                  >
                    <span class="my-auto ml-2">
                      <svg
                        class="email-icone"
                        width="19"
                        height="19"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.2677 9.06113L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95424 9.06113"
                          stroke="#999"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z"
                          stroke="#999"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>

                    <input
                      type="tel"
                      v-model="mobile"
                      class="bg-none w-100 h-100 fontsize-mini email-box"
                      placeholder="شماره خود را وارد کنید..."
                    />
                    <button
                      class="
                    fontsize-mini
                    text-white
                    py-1
                    px-3
                    align-self-end
                    newsletter-btn
                  "
                    >
                      عضویت
                    </button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="box-info-enamad mt-5 mb-5">
          <div class="info-shop-footer">
            <h6
              class="title-info-shop-footer text-color-444 font-900 fontsize-sm-18 font-weight-bold"
            >
              فروشگاه اینترنتی سجاد کمرون
            </h6>
            <template v-if="!loading">
              <p
                class="content-info-shop-footer fontsize-sm-13 mt-3 text-justify"
              >
                {{ homeData.settings.customer_setting.about_us_site }}
              </p>
            </template>
            <template v-else>
              <span
                ><b-skeleton width="105px" class="d-inline-block mr-2"
              /></span>
            </template>
          </div>
          <div class="enamde d-flex flex-row-reverse">
            <template v-if="!loading">
              <!-- <div v-if="homeData.settings.customer_setting.enamad" v-html="homeData.settings.customer_setting.enamad" class="box-enamad">

            </div> -->

              <a
                target="_blank"
                href="https://trustseal.enamad.ir/?id=226321&Code="
                ><img
                  referrerpolicy="origin"
                  src="@/assets/images/enamad.png"
                  alt=""
                  style="cursor:pointer"
                  Code=""
              /></a>
              <!-- <a target="_blank" href="https://trustseal.enamad.ir/?id=226321&Code="><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=226321&Code=' alt='' style='cursor:pointer' Code=''></a> -->
            </template>
            <template v-else>
              <span
                ><b-skeleton
                  width="105px"
                  height="105px"
                  class="d-inline-block mr-2"
              /></span>
            </template>
          </div>
        </div>
        <div
          class="webSiteCopyRight d-flex pb-2 flex-wrap align-items-center justify-content-between border-top border-grey-900 pt-3"
        >
          <section
            class="d-flex align-items-center text-sm-right text-center"
            style="gap: 5px;"
          >
            <svg
              class="d-sm-inline-block d-none"
              height="20"
              viewBox="0 0 512 512"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#666"
                d="m178.925781 177.925781c-42.5 42.5-42.5 111.648438 0 154.148438s111.648438 42.5 154.148438 0c7.8125-7.8125 20.476562-7.8125 28.285156 0s7.808594 20.476562 0 28.285156c-29.046875 29.046875-67.203125 43.570313-105.359375 43.570313s-76.3125-14.523438-105.359375-43.570313c-58.09375-58.097656-58.09375-152.625 0-210.71875 58.097656-58.09375 152.625-58.09375 210.71875 0 7.8125 7.808594 7.8125 20.472656 0 28.285156-7.808594 7.808594-20.472656 7.808594-28.285156 0-42.5-42.5-111.648438-42.5-154.148438 0zm258.09375-102.945312c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469 46.8125 0 92.617188-12.757812 132.460938-36.894531 9.449218-5.722657 12.46875-18.019531 6.746093-27.46875-5.726562-9.449219-18.023437-12.46875-27.46875-6.742188-33.59375 20.347657-72.234375 31.105469-111.738281 31.105469-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216c0 42.589844-12.664062 84.042969-36.625 119.882812-6.140625 9.183594-3.671875 21.605469 5.507812 27.742188 9.1875 6.140625 21.605469 3.671875 27.742188-5.507812 28.378906-42.441407 43.375-91.585938 43.375-142.117188 0-68.378906-26.628906-132.667969-74.980469-181.019531zm0 0"
              />
            </svg>
            <p class="fontsize13 text-color-999 m-0">
              تمامی حقوق این سایت مربوط به سایت سجاد کمرون می باشد
            </p>
          </section>
          <div class="Copyright">
            <p class="m-0">
              طراحی شده توسط گروه
              <a
                class="font-weight-bold"
                style="color: var(--color-theme)"
                target="_blank"
                href="https://shetabit.com/"
                >شتاب</a
              >
            </p>
          </div>
        </div>
      </section>
    </footer>
  </div>
</template>
<script>
import findUrl from "@/libraries/links/LinkResolver";
import ProductWarranty from "@/parts/Front/components/ProductWarranty";

import DynamicLinker from "./DynamicLinker";
import { BSkeleton } from "bootstrap-vue";
import ZWhatsappSvg from "./ZWhatsappSvg";
import SocialNetworks from "./SocialNetworks";

export default {
  name: "Footer",
  components: {
    SocialNetworks,
    ZWhatsappSvg,
    DynamicLinker,
    BSkeleton,
    ProductWarranty,
  },
  computed: {
    menus() {
      let homeData = this.$store.getters["front/getHomeData"];
      return homeData ? homeData.menu.footer : null;
    },
    // دریافت محتوای درخواست هوم
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  data() {
    return {
      mobile: null,
      email: null,
      disabled: false,
    };
  },
  created() {},
  methods: {
    findUrl,
    slugGenerator(item) {
      return item.slug ? item.slug : item.title.replace(" ", "-");
    },
    joinUs() {
      if (!this.mobile) {
        return window.swal({
          title: "خطا!",
          text: "لطفا شماره تماس خود را وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      } 
      const fromData = new FormData();
      fromData.append("phone_number", this.mobile);
      this.disabled = true;
      this.$axios.post("/front/newsletters", fromData).then((response) => {
        this.disabled = false;
        this.email = null;
        window.swal({
          title: "تبریک",
          text: "شماره شما برای عضویت در خبرنامه ما ثبت سیستم شد",
          icon: "success",
          timer: 2000,
        });
      }).catch(
        (err)=>{
          window.swal({
          title: "خطا!",
          text: err.response.data.errors.phone_number.toString(),
          icon: "warning",
          timer: 2000,
        });
        }
      )
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendMobile() {
      if (!this.mobile) {
        return window.swal({
          title: "خطا!",
          text: "لطفا شماره تماس خود را وارد کنید",
          icon: "warning",
          timer: 150000,
        });
      }

      const fromData = new FormData();
      fromData.append("mobile", this.mobile);
      if (this.$route.query.source) {
        fromData.append("source", this.$route.query.source);
      }
      this.disabled = true;
      this.$axios
        .post("/front/offers_newsletters", fromData)
        .then((response) => {
          this.disabled = false;
          this.mobile = null;
          window.swal({
            title: "تبریک",
            text: response.data.message,
            icon: "success",
            timer: 150000,
          });
        })
        .catch((err) => {
          this.$root.notify(err);
          this.disabled = false;
        });
    },
  },
};
</script>
<style scoped>
#sendEmailForm input {
  border: 1px solid #0000006b;
  border-left: unset !important;
}
#sendEmailForm button {
  border: 1px solid var(--color-theme);
}
.line-footer {
  flex-grow: 2;
  height: 1px;
  background-color: #e7e7e7;
}
.top-footer {
  gap: 16px;
}
.move-to-top {
  padding: 5px 16px;
  gap: 5px;
}

.arrow-top {
  transform: translateY(-2px);
}

.arrow-top svg {
  fill: #414141;
  width: 17px;
  height: 17px;
}

.list-menu-footer,
.box-info-enamad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.info-shop-footer {
  grid-column: 1/4;
}

.content-info-shop-footer {
  grid-column: 3/5;
  /*text-align: justify;*/
  font-size: 16px;
}

.sotial-networks svg {
  width: 26px;
  height: 26px;
}

.email-box {
  padding: 6px;
}

.nav {
  flex-direction: column;
}
.nav-link {
  padding: 0 !important;
}
.box-enamad img {
  width: 100px;
  height: 100px;
}

.imgSkeleton {
  height: 70px;
}

.errorEmail {
  border-bottom: 1px solid red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.newsletter-btn {
  background: var(--color-theme);
  padding: 7px 1rem !important;
}

.email-icone {
  width: 19px !important;
  height: 19px !important;
}

.select-menu-footer {
  padding: 8px 16px;
  display: none;
  /* width: 100%;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 15px;*/
}

.restemporary {
  display: block;
}

.summary::marker {
  content: "";
}

details[open] #icon-summery {
  transform: rotate(-90deg);
}

@media (min-width: 1024px) {
  .footer-call-location {
    position: relative;
  }

  .enamad-container {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1074px) {
  .address-footer {
    flex-direction: column;
    gap: 8px;
    margin-top: 3rem !important;
  }

  .address-footer div {
    align-items: start !important;
    width: 100%;
  }

  .line-address-footer {
    display: none !important;
  }

  .logo-footer {
    margin: unset !important;
  }

  .list-menu-footer {
    grid-template-columns: repeat(1, 1fr);
  }

  .list-menu-footer div {
    width: 100%;
  }

  section.sotial-networks {
    gap: 3rem;
    text-align: center;
    margin-top: 3rem;
  }

  .box-info-enamad {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .box-info-enamad div {
    width: 100%;
  }

  .enamde {
    justify-content: center;
  }

  .Copyright {
    font-size: 13px;
    color: var(--color-999);
  }

  /* با v-else زده شده بود */
  /* check */
  .select-menu-footer {
    padding: 8px 16px;
    display: block;
  }

  .restemporary {
    display: none;
  }
}

@media (max-width: 768px) {
  footer#footer {
    margin-bottom: 4rem;
  }

  .move-to-top {
    font-size: 14px;
    padding: 2px 16px !important;
  }

  .arrow-top {
    transform: translateY(0px) !important;
  }
  .logo-footer {
    width: 170px;
  }
}

@media (max-width: 500px) {
  .footer-container {
    margin-bottom: 5rem;
  }
  .address-filde {
    font-size: 13px !important;
  }

  .webSiteCopyRight {
    justify-content: center !important;
  }
  .logo-footer {
    width: 150px;
  }
  section.sotial-networks {
    gap: unset !important;
  }
}
</style>
<style>
.box-enamad img {
  width: 100px;
  height: 100px;
}
.nav-item *{
  color: black;
}
</style>
