<template>
  <section v-if="status || blogs.post.length" class="articles">
    <div class="container">
      <HeaderSection
        custom-style="background-image:linear-gradient(to right, #e6e6e6, #606060, #e6e6e6)"
        :textBtn="'مطالب بیشتر'"
        :status="status"
        :text="'پست ها'"
        :route="'/mag'"
      />
      <b-row v-if="status">
        <b-col>
          <b-skeleton-img></b-skeleton-img> <br />
          <b-skeleton></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img><br />
          <b-skeleton></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img><br />
          <b-skeleton></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img><br />
          <b-skeleton></b-skeleton>
        </b-col>
      </b-row>
      <div id="blogArticleContent" v-else class="articles-content">
        <!-- دریافت اطلاعات از آبجکت هوم و وارد شدن به آبجکت پست -->
        <template v-if="blogs != null">
          <article
            v-for="(blog, index) in blogs.post"
            :key="'Blog' + index"
            class=""
          >
            <router-link
              :to="{
                name: 'blogDetail',
                params: {
                  name: blog.en_title,
                },
              }"
            >
              <div class="article-img">
                <img
                  loading="lazy"
                  v-if="blog.image"
                  :src="blog.image.url"
                  :alt="blog.title"
                />
                <img
                  loading="lazy"
                  v-else
                  src="../../../assets/images/blog.jpg"
                  alt="blog"
                />
                <div class="category d-none d-sm-block">
                  <span class="ml-05">دسته‌بندی:</span>
                  <span class="font-weight-bold">{{ blog.category.name }}</span>
                </div>
                <div
                  class="
                article-img-hover
                d-flexs
                show-by-hover
                align-items-end
                justify-content-between
                px-4
              "
                >
                  <!--                  <span class="fontsize-mini weight-bold text-white ml-2">اطلاعات بیشتر</span>-->
                  <!--                  <svg-->
                  <!--                      class="mb-1"-->
                  <!--                      width="15"-->
                  <!--                      height="15"-->
                  <!--                      viewBox="0 0 24 24"-->
                  <!--                      fill="none"-->
                  <!--                      xmlns="http://www.w3.org/2000/svg"-->
                  <!--                  >-->
                  <!--                    <path-->
                  <!--                        fill-rule="evenodd"-->
                  <!--                        clip-rule="evenodd"-->
                  <!--                        d="M13.165 11.9934L13.1634 11.6393C13.1513 10.2348 13.0666 8.98174 12.9206 8.18763C12.9206 8.17331 12.7613 7.38572 12.6599 7.12355C12.5006 6.74463 12.2126 6.42299 11.8515 6.2192C11.5624 6.0738 11.2592 6 10.9417 6C10.6922 6.01157 10.2806 6.13714 9.98692 6.24242L9.74283 6.33596C8.12612 6.97815 5.03561 9.07656 3.85199 10.3598L3.76473 10.4495L3.37527 10.8698C3.12982 11.1915 3 11.5847 3 12.0077C3 12.3866 3.11563 12.7656 3.3469 13.0718C3.41614 13.171 3.52766 13.2983 3.62693 13.4058L4.006 13.8026C5.31046 15.1243 8.13485 16.9782 9.59883 17.5924C9.59883 17.6057 10.5086 17.9857 10.9417 18H10.9995C11.6639 18 12.2846 17.6211 12.6021 17.0086C12.6888 16.8412 12.772 16.5132 12.8352 16.2252L12.949 15.6813C13.0788 14.8067 13.165 13.465 13.165 11.9934ZM19.4967 13.5183C20.3269 13.5183 21 12.8387 21 12.0004C21 11.1622 20.3269 10.4825 19.4967 10.4825L15.7975 10.8097C15.1463 10.8097 14.6183 11.3417 14.6183 12.0004C14.6183 12.6581 15.1463 13.1912 15.7975 13.1912L19.4967 13.5183Z"-->
                  <!--                        fill="#fff"-->
                  <!--                    />-->
                  <!--                  </svg>-->
                </div>
              </div>
              <div class="text-right article-title text-color-666 p-1">
                <h6
                  class="d-inline-block fontsize-small weight-bold text-color-444 mt-3"
                >
                  <svg
                    class="mt-n1"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.36922 10.869C7.42572 10.811 7.63906 10.563 7.8378 10.359C9.00292 9.076 12.0424 6.976 13.6332 6.335C13.8748 6.232 14.4856 6.014 14.812 6C15.1247 6 15.4228 6.072 15.7073 6.218C16.0619 6.422 16.3463 6.743 16.5022 7.122C16.6025 7.385 16.7584 8.172 16.7584 8.186C16.9143 9.047 17 10.446 17 11.992C17 13.465 16.9143 14.807 16.7867 15.681C16.772 15.695 16.6162 16.673 16.4457 17.008C16.133 17.62 15.5222 18 14.8685 18H14.812C14.3863 17.985 13.491 17.605 13.491 17.591C11.9859 16.949 9.01656 14.952 7.82319 13.625C7.82319 13.625 7.48709 13.284 7.34096 13.071C7.11301 12.765 7 12.386 7 12.007C7 11.584 7.12762 11.19 7.36922 10.869Z"
                      fill="#444"
                    />
                  </svg>
                  {{ blog.title }}
                </h6>
                <p class="fontsize-mini">
                  {{
                    blog.summary
                      ? blog.summary.substr(0, blogSummaryLength)
                      : ""
                  }}
                  {{
                    blog.summary && blog.summary.length > blogSummaryLength
                      ? "..."
                      : ""
                  }}
                </p>
              </div>
            </router-link>
          </article>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderSection from "@/parts/Front/components/HeaderSection";
import { BSkeleton, BSkeletonImg, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    HeaderSection,
    BSkeleton,
    BSkeletonImg,
    BRow,
    BCol,
  },
  name: "Weblogs",
  mounted() {
    // get height of articles/
    let bodyWidth = document.body.offsetWidth;
    const articleImg = document.querySelectorAll(".article-img");
    if (bodyWidth < 767) {
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    }

    window.addEventListener("resize", function() {
      // get height of articles/
      // let bodyWidth=document.body.offsetWidth;
      const articleImg = document.querySelectorAll(".article-img");
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    });
  },

  computed: {
    blogSummaryLength() {
      return window.innerWidth < 550 ? 70 : 130;
    },
    blogs() {
      //دریافت اطلاعات برای قسمت بلاگ که در تمپلیت بالا وارد آبجکت پست از این آبجکت میشود
      console.log(this.$store.getters["front/getHomeData"]);
      return this.$store.getters["front/getHomeData"];
    },
    status() {
      ///نمایش لودینگ ها به کمک استاتوس
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
};
</script>

<style scoped>
.articles {
  background-color: #f5e7e7;
  padding: 1px 0 3.1rem 0;
}
.articles-content > article .article-img > img {
  transition: 300ms all;
}
.articles-content > article:hover .article-img > img {
  transform: scale(1.04);
}
.articles-content > article {
  background: white;
  padding: 3px;
}
.category {
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  color: #000;
  padding: 3px 10px;
  z-index: 2;
}
.ml-05 {
  margin-left: 2px;
}
</style>
